exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abrir-cuenta-js": () => import("./../../../src/pages/abrir-cuenta.js" /* webpackChunkName: "component---src-pages-abrir-cuenta-js" */),
  "component---src-pages-balanz-university-articulos-js": () => import("./../../../src/pages/balanz-university/articulos.js" /* webpackChunkName: "component---src-pages-balanz-university-articulos-js" */),
  "component---src-pages-balanz-university-glosario-financiero-articulos-js": () => import("./../../../src/pages/balanz-university/glosario-financiero/articulos.js" /* webpackChunkName: "component---src-pages-balanz-university-glosario-financiero-articulos-js" */),
  "component---src-pages-balanz-university-glosario-financiero-glossaries-slug-js": () => import("./../../../src/pages/balanz-university/glosario-financiero/{glossaries.slug}.js" /* webpackChunkName: "component---src-pages-balanz-university-glosario-financiero-glossaries-slug-js" */),
  "component---src-pages-balanz-university-glosario-financiero-index-js": () => import("./../../../src/pages/balanz-university/glosario-financiero/index.js" /* webpackChunkName: "component---src-pages-balanz-university-glosario-financiero-index-js" */),
  "component---src-pages-balanz-university-glosario-financiero-js": () => import("./../../../src/pages/balanz-university/glosario-financiero/[...].js" /* webpackChunkName: "component---src-pages-balanz-university-glosario-financiero-js" */),
  "component---src-pages-balanz-university-index-js": () => import("./../../../src/pages/balanz-university/index.js" /* webpackChunkName: "component---src-pages-balanz-university-index-js" */),
  "component---src-pages-balanz-university-js": () => import("./../../../src/pages/balanz-university/[...].js" /* webpackChunkName: "component---src-pages-balanz-university-js" */),
  "component---src-pages-balanz-university-university-articles-slug-js": () => import("./../../../src/pages/balanz-university/{universityArticles.slug}.js" /* webpackChunkName: "component---src-pages-balanz-university-university-articles-slug-js" */),
  "component---src-pages-buscar-js": () => import("./../../../src/pages/buscar.js" /* webpackChunkName: "component---src-pages-buscar-js" */),
  "component---src-pages-busquedas-abiertas-index-js": () => import("./../../../src/pages/busquedas-abiertas/index.js" /* webpackChunkName: "component---src-pages-busquedas-abiertas-index-js" */),
  "component---src-pages-busquedas-abiertas-js": () => import("./../../../src/pages/busquedas-abiertas/[...].js" /* webpackChunkName: "component---src-pages-busquedas-abiertas-js" */),
  "component---src-pages-busquedas-abiertas-searches-id-search-searches-position-name-js": () => import("./../../../src/pages/busquedas-abiertas/{searches.id_search}-{searches.position_name}.js" /* webpackChunkName: "component---src-pages-busquedas-abiertas-searches-id-search-searches-position-name-js" */),
  "component---src-pages-comisiones-js": () => import("./../../../src/pages/comisiones.js" /* webpackChunkName: "component---src-pages-comisiones-js" */),
  "component---src-pages-contacto-blanqueo-capitales-js": () => import("./../../../src/pages/contacto/blanqueo-capitales.js" /* webpackChunkName: "component---src-pages-contacto-blanqueo-capitales-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-contacto-nordelta-js": () => import("./../../../src/pages/contacto/nordelta.js" /* webpackChunkName: "component---src-pages-contacto-nordelta-js" */),
  "component---src-pages-cuenta-joven-js": () => import("./../../../src/pages/cuenta-joven.js" /* webpackChunkName: "component---src-pages-cuenta-joven-js" */),
  "component---src-pages-descarga-app-js": () => import("./../../../src/pages/descarga-app.js" /* webpackChunkName: "component---src-pages-descarga-app-js" */),
  "component---src-pages-empezar-a-invertir-js": () => import("./../../../src/pages/empezar-a-invertir.js" /* webpackChunkName: "component---src-pages-empezar-a-invertir-js" */),
  "component---src-pages-empresa-js": () => import("./../../../src/pages/empresa.js" /* webpackChunkName: "component---src-pages-empresa-js" */),
  "component---src-pages-empresas-js": () => import("./../../../src/pages/empresas.js" /* webpackChunkName: "component---src-pages-empresas-js" */),
  "component---src-pages-evento-en-vivo-js": () => import("./../../../src/pages/evento-en-vivo.js" /* webpackChunkName: "component---src-pages-evento-en-vivo-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-independent-financial-advisor-js": () => import("./../../../src/pages/independent-financial-advisor.js" /* webpackChunkName: "component---src-pages-independent-financial-advisor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inversiones-acciones-js": () => import("./../../../src/pages/inversiones/acciones.js" /* webpackChunkName: "component---src-pages-inversiones-acciones-js" */),
  "component---src-pages-inversiones-bonos-js": () => import("./../../../src/pages/inversiones/bonos.js" /* webpackChunkName: "component---src-pages-inversiones-bonos-js" */),
  "component---src-pages-inversiones-cauciones-js": () => import("./../../../src/pages/inversiones/cauciones.js" /* webpackChunkName: "component---src-pages-inversiones-cauciones-js" */),
  "component---src-pages-inversiones-cedears-cedear-de-etf-js": () => import("./../../../src/pages/inversiones/cedears/cedear-de-etf.js" /* webpackChunkName: "component---src-pages-inversiones-cedears-cedear-de-etf-js" */),
  "component---src-pages-inversiones-cedears-js": () => import("./../../../src/pages/inversiones/cedears.js" /* webpackChunkName: "component---src-pages-inversiones-cedears-js" */),
  "component---src-pages-inversiones-cedears-packs-de-cedears-js": () => import("./../../../src/pages/inversiones/cedears/packs-de-cedears.js" /* webpackChunkName: "component---src-pages-inversiones-cedears-packs-de-cedears-js" */),
  "component---src-pages-inversiones-dolar-mep-js": () => import("./../../../src/pages/inversiones/dolar-mep.js" /* webpackChunkName: "component---src-pages-inversiones-dolar-mep-js" */),
  "component---src-pages-inversiones-dolares-js": () => import("./../../../src/pages/inversiones/dolares.js" /* webpackChunkName: "component---src-pages-inversiones-dolares-js" */),
  "component---src-pages-inversiones-echeqs-js": () => import("./../../../src/pages/inversiones/echeqs.js" /* webpackChunkName: "component---src-pages-inversiones-echeqs-js" */),
  "component---src-pages-inversiones-fondos-abierto-infraestructura-js": () => import("./../../../src/pages/inversiones/fondos/abierto-infraestructura.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-abierto-infraestructura-js" */),
  "component---src-pages-inversiones-fondos-abierto-pymes-js": () => import("./../../../src/pages/inversiones/fondos/abierto-pymes.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-abierto-pymes-js" */),
  "component---src-pages-inversiones-fondos-acciones-js": () => import("./../../../src/pages/inversiones/fondos/acciones.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-acciones-js" */),
  "component---src-pages-inversiones-fondos-ahorro-en-dolares-js": () => import("./../../../src/pages/inversiones/fondos/ahorro-en-dolares.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-ahorro-en-dolares-js" */),
  "component---src-pages-inversiones-fondos-ahorro-js": () => import("./../../../src/pages/inversiones/fondos/ahorro.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-ahorro-js" */),
  "component---src-pages-inversiones-fondos-crecimiento-js": () => import("./../../../src/pages/inversiones/fondos/crecimiento.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-crecimiento-js" */),
  "component---src-pages-inversiones-fondos-desarrollo-js": () => import("./../../../src/pages/inversiones/fondos/desarrollo.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-desarrollo-js" */),
  "component---src-pages-inversiones-fondos-equity-selection-js": () => import("./../../../src/pages/inversiones/fondos/equity-selection.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-equity-selection-js" */),
  "component---src-pages-inversiones-fondos-estrategia-i-js": () => import("./../../../src/pages/inversiones/fondos/estrategia-i.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-estrategia-i-js" */),
  "component---src-pages-inversiones-fondos-estrategia-ii-js": () => import("./../../../src/pages/inversiones/fondos/estrategia-ii.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-estrategia-ii-js" */),
  "component---src-pages-inversiones-fondos-estrategia-iii-js": () => import("./../../../src/pages/inversiones/fondos/estrategia-iii.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-estrategia-iii-js" */),
  "component---src-pages-inversiones-fondos-excalibur-regisseur-js": () => import("./../../../src/pages/inversiones/fondos/excalibur-regisseur.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-excalibur-regisseur-js" */),
  "component---src-pages-inversiones-fondos-gestion-js": () => import("./../../../src/pages/inversiones/fondos/gestion.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-gestion-js" */),
  "component---src-pages-inversiones-fondos-institucional-inflation-linked-js": () => import("./../../../src/pages/inversiones/fondos/institucional-inflation-linked.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-institucional-inflation-linked-js" */),
  "component---src-pages-inversiones-fondos-js": () => import("./../../../src/pages/inversiones/fondos.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-js" */),
  "component---src-pages-inversiones-fondos-liquidez-opportunity-js": () => import("./../../../src/pages/inversiones/fondos/liquidez-opportunity.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-liquidez-opportunity-js" */),
  "component---src-pages-inversiones-fondos-money-market-dolares-js": () => import("./../../../src/pages/inversiones/fondos/money-market-dolares.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-money-market-dolares-js" */),
  "component---src-pages-inversiones-fondos-money-market-js": () => import("./../../../src/pages/inversiones/fondos/money-market.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-money-market-js" */),
  "component---src-pages-inversiones-fondos-perfomance-iv-js": () => import("./../../../src/pages/inversiones/fondos/perfomance-iv.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-perfomance-iv-js" */),
  "component---src-pages-inversiones-fondos-perfomance-xi-js": () => import("./../../../src/pages/inversiones/fondos/perfomance-xi.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-perfomance-xi-js" */),
  "component---src-pages-inversiones-fondos-performance-i-js": () => import("./../../../src/pages/inversiones/fondos/performance-i.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-performance-i-js" */),
  "component---src-pages-inversiones-fondos-performance-ii-js": () => import("./../../../src/pages/inversiones/fondos/performance-ii.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-performance-ii-js" */),
  "component---src-pages-inversiones-fondos-performance-iii-js": () => import("./../../../src/pages/inversiones/fondos/performance-iii.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-performance-iii-js" */),
  "component---src-pages-inversiones-fondos-performance-iv-js": () => import("./../../../src/pages/inversiones/fondos/performance-iv.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-performance-iv-js" */),
  "component---src-pages-inversiones-fondos-performance-v-js": () => import("./../../../src/pages/inversiones/fondos/performance-v.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-performance-v-js" */),
  "component---src-pages-inversiones-fondos-performance-xi-js": () => import("./../../../src/pages/inversiones/fondos/performance-xi.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-performance-xi-js" */),
  "component---src-pages-inversiones-fondos-performance-xii-js": () => import("./../../../src/pages/inversiones/fondos/performance-xii.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-performance-xii-js" */),
  "component---src-pages-inversiones-fondos-performance-xiii-js": () => import("./../../../src/pages/inversiones/fondos/performance-xiii.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-performance-xiii-js" */),
  "component---src-pages-inversiones-fondos-renta-fija-dolar-linked-js": () => import("./../../../src/pages/inversiones/fondos/renta-fija-dolar-linked.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-renta-fija-dolar-linked-js" */),
  "component---src-pages-inversiones-fondos-renta-fija-en-dolares-js": () => import("./../../../src/pages/inversiones/fondos/renta-fija-en-dolares.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-renta-fija-en-dolares-js" */),
  "component---src-pages-inversiones-fondos-renta-fija-estrategica-js": () => import("./../../../src/pages/inversiones/fondos/renta-fija-estrategica.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-renta-fija-estrategica-js" */),
  "component---src-pages-inversiones-fondos-renta-fija-opportunity-js": () => import("./../../../src/pages/inversiones/fondos/renta-fija-opportunity.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-renta-fija-opportunity-js" */),
  "component---src-pages-inversiones-fondos-rentabilidades-fci-js": () => import("./../../../src/pages/inversiones/fondos/rentabilidades-fci.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-rentabilidades-fci-js" */),
  "component---src-pages-inversiones-fondos-retorno-total-js": () => import("./../../../src/pages/inversiones/fondos/retorno-total.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-retorno-total-js" */),
  "component---src-pages-inversiones-fondos-soja-js": () => import("./../../../src/pages/inversiones/fondos/soja.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-soja-js" */),
  "component---src-pages-inversiones-fondos-sudamericano-js": () => import("./../../../src/pages/inversiones/fondos/sudamericano.js" /* webpackChunkName: "component---src-pages-inversiones-fondos-sudamericano-js" */),
  "component---src-pages-inversiones-futuros-js": () => import("./../../../src/pages/inversiones/futuros.js" /* webpackChunkName: "component---src-pages-inversiones-futuros-js" */),
  "component---src-pages-inversiones-js": () => import("./../../../src/pages/inversiones.js" /* webpackChunkName: "component---src-pages-inversiones-js" */),
  "component---src-pages-inversiones-letras-js": () => import("./../../../src/pages/inversiones/letras.js" /* webpackChunkName: "component---src-pages-inversiones-letras-js" */),
  "component---src-pages-inversiones-obligaciones-negociables-js": () => import("./../../../src/pages/inversiones/obligaciones-negociables.js" /* webpackChunkName: "component---src-pages-inversiones-obligaciones-negociables-js" */),
  "component---src-pages-inversiones-opciones-js": () => import("./../../../src/pages/inversiones/opciones.js" /* webpackChunkName: "component---src-pages-inversiones-opciones-js" */),
  "component---src-pages-inverti-tu-aguinaldo-old-js": () => import("./../../../src/pages/inverti-tu-aguinaldo_old.js" /* webpackChunkName: "component---src-pages-inverti-tu-aguinaldo-old-js" */),
  "component---src-pages-investment-ideas-articulos-js": () => import("./../../../src/pages/investment-ideas/articulos.js" /* webpackChunkName: "component---src-pages-investment-ideas-articulos-js" */),
  "component---src-pages-investment-ideas-index-js": () => import("./../../../src/pages/investment-ideas/index.js" /* webpackChunkName: "component---src-pages-investment-ideas-index-js" */),
  "component---src-pages-investment-ideas-investment-articles-slug-js": () => import("./../../../src/pages/investment-ideas/{investmentArticles.slug}.js" /* webpackChunkName: "component---src-pages-investment-ideas-investment-articles-slug-js" */),
  "component---src-pages-investment-ideas-js": () => import("./../../../src/pages/investment-ideas/[...].js" /* webpackChunkName: "component---src-pages-investment-ideas-js" */),
  "component---src-pages-legales-js": () => import("./../../../src/pages/legales.js" /* webpackChunkName: "component---src-pages-legales-js" */),
  "component---src-pages-preguntas-frecuentes-js": () => import("./../../../src/pages/preguntas-frecuentes.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-js" */),
  "component---src-pages-reportes-daily-js": () => import("./../../../src/pages/reportes/daily.js" /* webpackChunkName: "component---src-pages-reportes-daily-js" */),
  "component---src-pages-reportes-monthly-js": () => import("./../../../src/pages/reportes/monthly.js" /* webpackChunkName: "component---src-pages-reportes-monthly-js" */),
  "component---src-pages-reportes-prensa-js": () => import("./../../../src/pages/reportes/prensa.js" /* webpackChunkName: "component---src-pages-reportes-prensa-js" */),
  "component---src-pages-reportes-reportes-js": () => import("./../../../src/pages/reportes/reportes.js" /* webpackChunkName: "component---src-pages-reportes-reportes-js" */),
  "component---src-pages-reportes-weekly-fondos-js": () => import("./../../../src/pages/reportes/weekly-fondos.js" /* webpackChunkName: "component---src-pages-reportes-weekly-fondos-js" */),
  "component---src-pages-reportes-weekly-market-js": () => import("./../../../src/pages/reportes/weekly-market.js" /* webpackChunkName: "component---src-pages-reportes-weekly-market-js" */),
  "component---src-pages-sustentabilidad-js": () => import("./../../../src/pages/sustentabilidad.js" /* webpackChunkName: "component---src-pages-sustentabilidad-js" */),
  "component---src-pages-test-del-inversor-js": () => import("./../../../src/pages/test-del-inversor.js" /* webpackChunkName: "component---src-pages-test-del-inversor-js" */),
  "component---src-pages-trabaja-con-nosotros-index-js": () => import("./../../../src/pages/trabaja-con-nosotros/index.js" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-index-js" */),
  "component---src-pages-trabaja-con-nosotros-js": () => import("./../../../src/pages/trabaja-con-nosotros/[...].js" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-js" */),
  "component---src-pages-trabaja-con-nosotros-searches-id-search-searches-position-name-js": () => import("./../../../src/pages/trabaja-con-nosotros/{searches.id_search}-{searches.position_name}.js" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-searches-id-search-searches-position-name-js" */)
}

